@font-face {
    font-family: 'Sailing South';
    src: url(../components/Fonts/Sailing\ South\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

.orderHereWrapper {

    background-color: #fffbff;
}


.orderHereHeaderWrapper {

    display: flex;
    justify-content: center;
}

.orderHereHeader {

    font-family: 'Tough Regular';
    letter-spacing: 0px;
}

.orderDetails {

    font-family: 'Crimson Text', serif;
}

.orderHereSubHeaderWrapper {

    display: flex;
    justify-content: center;
}

.orderHereSubHeader {

    font-family: 'Crimson Text', serif;
}

.orderHereContactFormWrapper {

    display: flex;
    justify-content: center;
    background-color: #fffbff;
    height: 100%;
}

.orderHereContainer {

    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1); */
    object-fit: contain;
    /* justify-content: center;  */
    position: absolute;
    
}

.orderNeededDetailsWrapper {

    display: flex;
    justify-content: center;
}

.orderNowVideo {

    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.orderFormWrapper {

    /* display: flex;
    justify-content: flex-end; */
    position: absolute;
    background-color: #000d1a;
    font-family: 'Crimson Text', serif;
}

.orderNowForm {

    color: white;
}


.orderButton {

    color: white;
    background-color: pink;
    cursor: pointer;
    font-family: 'Crimson Text', serif;
}

.orderButton:hover {

    background-color: #Ffc439;
}

@media screen and (max-width: 2400px){

    .orderHereWrapper {

        padding-top: 80px;
    }

    
    .orderHereInstructionsWrapper {
    
        text-align: center;
    }

    .orderHereHeaderWrapper {

        padding-bottom: 15px;
    }

    .orderHereHeader {
        
        font-size: 27px;
    }

    .orderHereContactFormWrapper {

        height: 470px;
    }

    .orderHereContainer {

        width: 50%;
        padding-top: 30px;
    }

    .orderFormWrapper {

        width: 40%;
    }

    .orderNowForm {

        padding: 10px 10px;
        height: 370px;
    }

    .orderNowVideo {

        height: 390px;
    }

    .orderFormContent {

        padding-top: 12px;
    }
    

    .formName {
    
        padding: 5px 0px;
    }
    
    .messageTextArea {
    
        height: 85px;
        width: 210px;
    }


    .orderButtonWrapper {

        padding-top: 20px;
    }

    .orderButton {

        padding: 8px 12px;
        font-size: 15px;
    }

}

@media screen and (max-width: 1280px){

    .orderFormWrapper {

        width: 45%;
    }


    
}

@media screen and (max-width: 1050px){


    .orderHereContainer {

        height: 370px;
        width: 60%;
        padding-top: 30px;
    }
}

@media screen and (max-width: 975px){

    .orderFormWrapper {

        width: 50%;
    }
}

@media screen and (max-width: 875px){

    .orderHereContainer {

        width: 70%;
    }
}

@media screen and (max-width: 750px){

    .orderHereContainer {

        width: 80%;
    }
}

@media screen and (max-width: 650px){

    .orderHereContainer {

        width: 90%;
    }
}

@media screen and (max-width: 520px){

    .orderHereWrapper {

        height: 550px;
    }

    .orderHereContainer {

        width: 80%;
    }

    .orderFormWrapper {

        width: 60%;
    }
}

@media screen and (max-width: 490px){

    .orderHereInstructionsWrapper {
    
        padding: 0px 20px;
    }

    .orderNowVideo {

        display: none;
    }

    .orderNowForm {

        height: 380px;
    }

    .orderFormWrapper {

        width: 100%;
    }
}

@media screen and (max-width: 390px){

    .orderHereHeader {

        font-size: 24px;
    }

    .messageTextArea {

        width: 190px;
    }

    .orderButton {

        padding: 8px 10px;
        font-size: 14px;
    }
}

@media screen and (max-width: 335px){

    .orderHereHeader {

        font-size: 23px;
    }
}

@media screen and (max-width: 320px){

    .orderButton {

        padding: 8px 10px;
        font-size: 13px;
    }
}

@media screen and (max-width: 290px){

    .orderHereHeader {

        font-size: 22px;
    }
}