@font-face {
    font-family: 'Sailing South';
    src: url(../components/Fonts/Sailing\ South\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tough Regular';
    src: url(../components/Fonts/Tough\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tropical Regular';
    src: url(../components/Fonts/Tropical\ Serif\ Font\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

.ultimateFooterWrapper {

	background-color: #efcfd4;
	
}
.deliveringHeaderWrapper {

	display: flex;
	justify-content: center;
    background-color: #efcfd4;
}

.footerWrapper {

    background-color: #efcfd4;
	display: flex;
    flex-wrap: wrap;

}

.footerMainWrapper {

	display:flex;
	justify-content: center;
    background-color: #efcfd4;
}

.socialLogo {

    color: white;

}

.firstLogo  {

	color: white;
}

.builtByWrapper {

	display: flex;
	justify-content: center;
	text-align: center;
	background-color: #efcfd4;
}

.contactUsWrapper {

	width: 25%;
	display: flex;
	justify-content: flex-end;
}


.deliveringHeader {

	color: white;
	font-family: 'Tough Regular';
}

/* .builtByWrapper {

	display: flex;
	justify-content: center;
} */

.builtbySophia {

	color: white;
}

.builtByLink {

	text-decoration: none;
	color: white;
	cursor: pointer;
}

.builtByLink:hover {

	color: black;
}

.contactUsWrapper {

	color: white;
	text-align: end;
}

.allRightsWrapper {

	display: flex;
	justify-content: center;
	background-color: #efcfd4;
	color: white;
}

.animate__animated.animate__pulse {
	animation-duration: 3s;
	animation-delay: 3s;
}

@media screen and (max-width: 2400px){


	.socialLogo {

		padding-left: 25px;
		width: 24px;
		height: 24px;
	}

	.firstLogo {

		width: 24px;
		height: 24px;
	}

	.deliveringHeaderWrapper {

		padding-bottom: 10px;
		padding-top: 20px;
	}

	.deliveringHeader {

		font-size: 36px;
	}

	.footerMainWrapper {

		padding-top: 8px;
	}

	.builtByWrapper {

		padding-top: 25px;
		padding-bottom: 4px;
	}

	.builtbySophia {

		font-size: 11px;
	}

	.builtByLink {

		padding-left: 3px;
	}



	.allRightsWrapper {

		padding-bottom: 5px;
	}

	.allRights {

		font-size: 11px;
	}

}

@media screen and (max-width: 768px){

	.socialLogo {

		padding-left: 23px;
	}

}

/* @media screen and (max-width: 690px){


} */


@media screen and (max-width: 600px){

	.socialLogo {

		width: 23px;
		height: 23px;
	}

	.firstLogo {

		width: 23px;
		height: 23px;
	}
}

@media screen and (max-width: 540px){


	.socialLogo {
		padding-left: 22px;
	}
}

@media screen and (max-width: 500px){

	.deliveringHeader {

		font-size: 30px;
	}

	.footerMainWrapper {

		padding-top: 8px;
	}

	.builtByWrapper {

		padding-top: 20px;
	}

}

@media screen and (max-width: 460px){

	.deliveringHeader {

		font-size: 31px;
	}


	.footerMainWrapper {

		padding-top: 7px;
	}


	.socialLogo {

		width: 22px;
		height: 22px;
	}
}

@media screen and (max-width: 430px){

	

	.footerMainWrapper {

		padding-top: 7px;
	}

	.socialLogo {

		padding-left: 18px;
	}


	.builtByWrapper {

		padding-top: 20px;
	}

	.builtbySophia {

		font-size: 10px;
	}

	.allRights {

		font-size: 10px;
	}
}

@media screen and (max-width: 414px){

	.deliveringHeaderWrapper {

		padding-top: 15px;
		padding-bottom: 15px;
	}

	.deliveringHeader {

		font-size: 31px;
	}

	.firstLogo {

		padding-left: 0px;
	}
}

@media screen and (max-width: 390px){

	.deliveringHeaderWrapper {

		padding-bottom: 7px;
		padding-top: 22px;
	}

	.deliveringHeader {

		font-size: 30px;
	}

	.socialLogo {

		padding-left: 20px;
	}

	.firstLogo {

		padding-left: 0px;
	}

}

@media screen and (max-width: 375px){

	.deliveringHeader {

		font-size: 29px;
	}

	.footerMainWrapper {

		padding-top: 9px;
	}

	.builtbySophia  {

		font-size: 9px;
	}

	.allRights {

		font-size: 9px;
	}

}

@media screen and (max-width: 350px){

	.deliveringHeader {

		font-size: 28px;
	}

	.allRightsWrapper {

		padding-top: 3px;
		padding-left: 60px;
		padding-right: 60px;
		text-align: center;
	}
}

@media screen and (max-width: 320px){

	.deliveringHeaderWrapper {

		padding-bottom: 0px;
		padding-top: 25px;
	}

	.deliveringHeader {

		font-size: 26px;
	}

	.footerMainWrapper {

		padding-top: 12px;
	}

	.builtByWrapper {

		padding-top: 21px;
	}

	.allRightsWrapper {

		padding-top: 3px;
		padding-left: 42px;
		padding-right: 42px;
		text-align: center;
	}
}

@media screen and (max-width: 300px){

	.deliveringHeader {

		font-size: 28px;
	}

	.footerMainWrapper {

		padding-top: 15px;
	}

	.socialLogo {

		padding-left: 17px;
	}

	.builtByWrapper {

		padding-top: 25px;
	}

	.allRightsWrapper {

		padding-top: 3px;
		padding-left: 35px;
		padding-right: 35px;
	}
}

@media screen and (max-width: 280px){

	.deliveringHeader {

		font-size: 26px;
	}

	.footerMainWrapper {

		padding-top: 18px;
	}


	.socialLogo  {

		padding-left: 17px;
	}

	.allRightsWrapper {

		padding-left: 26px;
		padding-right: 26px;
	}
}
