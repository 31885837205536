/* Modal */

.modalBackground {

    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    /* transform: translateY(-80vh); */
}

/* .modalBackground {

    transform: translateY(-20vh);
    width: 100%;
    height: 500vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 101;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;  
    height: 600px;  
} */

/* .modalContainer {


    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 202;

    top: 56vh;
    transform: translateY(-310px);
} */

.modalContainer {

    display: flex;
    justify-content: center;
    z-index: 202;
    height:  500px;
    background-image: url("../images/mainVideoStill.png");
}


/* Modal Popup */

.modalComponent {

    z-index: 300;
    position: relative;
}

.modalPopupContainer {

    /* height: 100%; */
    /* box-shadow: 0 5px 16px rgba(187, 15, 15, 0.2); */
    background: #fdf0f1;
    display: flex;
    justify-content: center;
    
}


.closeButtonLink {

    color: black;
}

.modalPopupCloseButton {

    position: absolute;
    z-index: 400;
    cursor: pointer;
}

@media screen and (max-width: 2400px){

    .modalBackground {

        height: 500vh;
        z-index: 100;
        width: 100%;
    }

    .modalContainer {

        padding-top: 40px;
    }

    .modalComponent {

        height: 300px;
    }

    .modalPopupContainer {

        padding: 30px 30px;
    }

    .blackBloomVideo {

        height: 315px;
        width: 560px;
    }

    .modalPopupCloseButton {

        width: 24px;
        height: 24px;
        top: 9px;
        right: 4px;
    }
}

@media screen and (max-width: 768px){

    .blackBloomVideo {

        height: 300px;
        width: 545px;
    }
}

@media screen and (max-width: 725px){

    .modalPopupContainer {

        padding: 25px 25px;
    }

    .blackBloomVideo {

        height: 280px;
        width: 525px;
    }

    .modalPopupCloseButton {

        width: 23px;
        height: 23px;
        top: 7px;
        right: 2px;
    }
}

@media screen and (max-width: 680px){

    .modalPopupContainer {

        padding: 22px 22px;
    }

    .blackBloomVideo {

        height: 280px;
        width: 505px;
        padding-top: 10px;
    }

    .modalPopupCloseButton {

        width: 22px;
        height: 22px;
        top: 6px;
        right: 1px;
    }

}

@media screen and (max-width: 624px){

    .blackBloomVideo {

        height: 275px;
        width: 490px;
    }

}

@media screen and (max-width: 590px){

    .modalPopupContainer {

        padding: 20px 20px;
    }

    .blackBloomVideo {

        height: 260px;
        width: 470px;
    }

    .modalPopupCloseButton {

        top: 4px;
    }

}

@media screen and (max-width: 560px){

    .modalPopupContainer {

        padding: 20px 20px;
    }

    .blackBloomVideo {

        height: 280px;
        width: 400px;
    }

    .modalPopupCloseButton {

        top: 4px;
        right: 0px;
    }

}

@media screen and (max-width: 540px){

    .modalPopupContainer {

        padding: 20px 20px;
    }

    .blackBloomVideo {

        height: 280px;
        width: 380px;
    }

    .modalPopupCloseButton {

        top: 4px;
        right: 0px;
    }

}

@media screen and (max-width: 500px){

    .modalPopupContainer {

        padding: 20px 20px;
    }

    .blackBloomVideo {

        height: 270px;
        width: 360px;
    }

    .modalPopupCloseButton {

        top: 4px;
        right: 0px;
    }

}

@media screen and (max-width: 475px){


    .blackBloomVideo {

        height: 270px;
        width: 340px;
    }

}

@media screen and (max-width: 450px){


    .blackBloomVideo {

        padding-top: 20px;
        height: 260px;
        width: 320px;
    }

}

@media screen and (max-width: 425px){


    .blackBloomVideo {

        height: 250px;
        width: 300px;
    }

}

@media screen and (max-width: 414px){


    .blackBloomVideo {

        height: 230px;
        padding-top: 30px;
        width: 280px;
    }

}

@media screen and (max-width: 390px){

    .blackBloomVideo {

        height: 230px;
        padding-top: 35px;
        width: 270px;
    }
}


@media screen and (max-width: 360px){

    .blackBloomVideo {

        height: 190px;
        padding-top: 55px;
        width: 240px;
    }
}

@media screen and (max-width: 320px){

    .blackBloomVideo {

        height: 170px;
        padding-top: 65px;
        width: 220px;
    }
}

@media screen and (max-width: 300px){

    .blackBloomVideo {

        padding-top: 45px;
        width: 215px;
    }
}

@media screen and (max-width: 280px){

    .blackBloomVideo {

        height: 170px;
        padding-top: 65px;
        width: 210px;
    }
}