.navbarWrapper {

    background:  #000d1a;
}

.faBarsLink {

    color: white;
}

/* .faBarsIcon {

    height: 16px;
    width: 16px;
    color: white;
    position: absolute;
    right: 0;
    margin-top: 35px;
    margin-right: 30px;
} */

.logoWrapper {

    display: flex;
    justify-content: center;

}


@media screen and (max-width: 2400px){

    .logoWrapper {

        padding-top: 10px;
        padding-bottom: 10px;
    }

    .businessLogo {

        height: 60px;
    }
}

@media screen and (max-width: 768px){

    .businessLogo {

        height: 55px;
    }
}

@media screen and (max-width: 650px){

    .businessLogo {

        height: 50px;
    }
}

@media screen and (max-width: 540px){

    .businessLogo {

        height: 45px;
    }
}

@media screen and (max-width: 480px){

    .businessLogo {

        height: 40px;
    }

}

@media screen and (max-width: 450px){

    .businessLogo {

        height: 37px;
    }

}

@media screen and (max-width: 414px){

    .logoWrapper {

        padding-top: 8px;
        padding-bottom: 8px;
    }

    .businessLogo {

        height: 35px;
    }

}

@media screen and (max-width: 390px){

    .businessLogo {

        height: 34px;
    }
}

@media screen and (max-width: 375px){


}

@media screen and (max-width: 320px){





}