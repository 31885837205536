@font-face {
    font-family: 'Sailing South';
    src: url(../components/Fonts/Sailing\ South\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tough Regular';
    src: url(../components/Fonts/Tough\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tropical Regular';
    src: url(../components/Fonts/Tropical\ Serif\ Font\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

.aboutMeWrapper {

    display: flex;
    flex-direction: column;
    justify-content: center;
    background:  #000d1a;
}

.aboutMeContainer {

    position: relative;
    background:  #000d1a;
}

.aboutMeWrap {
    
    margin: 0 auto;
}

.aboutMeImageWrapper {

    position: absolute;

}

.aboutMeImage {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.aboutMeContentWrapper {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color:  #fffbff;
    
}

.aboutMeHeader {

    font-family: 'Sailing South';
    letter-spacing:2px;
}

.dianaWords {

    font-family: 'Sailing South';
    letter-spacing:2px;

    font-family: 'Crimson Text', serif;
}

.aboutMeDescription {

    font-family: 'Crimson Text', serif;
}

.instagramShoutout {

    font-family: 'Crimson Text', serif;
}


.blackandbloomInsta {

    color: pink;
}

@media screen and (max-width: 2400px){

    .aboutMeWrapper {

        padding-top: 200px;
        padding-bottom: 200px;
    }

    .aboutMeImageWrapper {

        right: 0px;
        max-width: 400px;
        margin-top: -100px;
        width: 60%;
    }

    .aboutMeContentWrapper {

        padding: 40px 60px;
        
    }
    
    .aboutMeContentContainer {

        flex: 0 0 60%;
        line-height: 28px;
    }

    .aboutMeHeader {

        font-size: 36px;
        padding-bottom: 4px;
    }

    .dianaWords {

        padding-top: 25px;
        font-size: 17px;
        padding-left: 50px;
        padding-right: 30px;
    }
    
    .aboutMeDescription {
    
        padding-top: 25px;
        font-size: 18px;
    }
    
    .instagramShoutout {
    
        padding-top: 12px;
        font-size: 18px;
    }

    .blackandbloomInsta {

        padding-left: 5px;
        padding-right: 5px;
    }

}
@media screen and (max-width: 1380px){

    .aboutMeWrapper {

        padding-top: 200px;
        padding-bottom: 200px;
    }


    .aboutMeImageWrapper {

        right: 0px;
        max-width: 400px;
        margin-top: -105px;
        width: 60%;
    }

}

@media screen and (max-width: 1298px){

    .aboutMeImageWrapper {

        margin-top: -100px;
    }
}

@media screen and (max-width: 1250px){

    .aboutMeImageWrapper {

        margin-top: -90px;
    }
}

@media screen and (max-width: 1185px){

    .aboutMeImageWrapper {

        margin-top: -75px;
    }

}

@media screen and (max-width: 1163px){

    .aboutMeImageWrapper {

        right: 0px;
        max-width: 390px;
        margin-top: -70px;
        width: 60%;

    }
}

@media screen and (max-width: 1060px){

    .aboutMeWrapper  {

        padding-top: 180px;
        padding-bottom: 180px;
    }

    .aboutMeContentWrapper {

        padding: 40px 50px;
    }

    .aboutMeImageWrapper {

        max-width: 385px;
        margin-top: -70px;
        width: 60%;
    }
}

@media screen and (max-width: 1024px){

    .aboutMeWrapper  {

        padding-top: 170px;
        padding-bottom: 170px;
    }

    .aboutMeContentWrapper {

        padding: 40px 55px;
    }

    .aboutMeImageWrapper {

        max-width: 380px;
        margin-top: -65px;
        width: 60%;
    }

    .aboutMeHeader {

        font-size: 34px;
    }

    .dianaWords {

        padding-top: 22px;
        font-size: 16px;
        padding-left: 37px;
    }

    .aboutMeDescription {

        padding-top: 22px;
        font-size: 17px;
    }

    .instagramShoutout {

        font-size: 17px;
        padding-top: 11px;
    }
}

@media screen and (max-width: 1000px){

    .aboutMeWrapper  {

        padding-top: 180px;
        padding-bottom: 180px;
    }

    .aboutMeImageWrapper {

        max-width: 370px;
        margin-top: -60px;
        width: 60%;
    }
}

@media screen and (max-width: 975px){

    .aboutMeContentWrapper {

        padding: 40px 40px;
    }

    .aboutMeImageWrapper {

        max-width: 360px;
        margin-top: -60px;
        width: 60%;
    }

}

@media screen and (max-width: 940px){

    .aboutMeContentWrapper {

        padding: 40px 25px;
    }

    .aboutMeImageWrapper {

        max-width: 370px;
        margin-top: -60px;
        width: 60%;
    }

    .dianaWords {

        padding-right: 50px;
        line-height: 27px;
        padding-top: 20px;
    }
    
    .aboutMeDescription {
        
        padding-right: 20px;
        padding-top: 20px;
    }

    .instagramShoutout  {

        padding-top: 10px;
    }

}

@media screen and  (max-width: 925px){

    .aboutMeWrapper  {

        padding-top: 160px;
        padding-bottom: 160px;
    }

    .aboutMeImageWrapper {

        margin-top: -45px;
    }

    .aboutMeHeader {

        font-size: 32px;
    }

    .dianaWords {

        padding-right: 60px;
        line-height: 26px;
        padding-top: 18px;
    }

    .aboutMeDescription {
        
        padding-right: 35px;
        padding-top: 18px;
    }
}



@media screen and (max-width: 875px){

    .aboutMeImageWrapper {

        margin-top: -50px;
    }

    .aboutMeContentWrapper {

        padding: 35px 25px;
    }

    .dianaWords {

        line-height: 25px;
        padding-top: 17px;
        padding-right: 65px;
        padding-left: 28px;
        font-size: 15px;
    }

    .aboutMeDescription {

        padding-right: 50px;
    }

}

@media screen and (max-width: 850px){

    .aboutMeImageWrapper {

        height: 600px;
        width: 340px;
        margin-top: -70px;
    }
}



@media screen and (max-width: 815px){

    .aboutMeImageWrapper {

        height: 570px;
        width: 330px;
        margin-top: -60px;
    }

    .aboutMeHeader {

        font-size: 31px;
    }
}

@media screen and (max-width: 790px){

    .aboutMeWrapper  {

        padding-top: 155px;
        padding-bottom: 155px;
    }

    .aboutMeImageWrapper {

        height: 570px;
        width: 320px;
        margin-top: -60px;
    }

    .dianaWords {

        line-height: 24px;
    }

    .aboutMeDescription {

        font-size: 16px;
    }

    .instagramShoutout {

        font-size: 16px;
    }
}

@media screen and (max-width: 765px){

    .aboutMeImageWrapper {

        height: 580px;
        width: 320px;
        margin-top: -35px;
    }

}

@media screen and (max-width: 730px){

    .aboutMeImageWrapper {

        height: 610px;
        width: 310px;
        margin-top: -55px;
    }
    

}

@media screen and (max-width: 715px){

    .aboutMeImageWrapper {

        height: 610px;
        width: 300px;
        margin-top: -45px;
    }
}


@media screen and (max-width: 700px){

    .aboutMeImageWrapper {

        margin-top: -50px;
        width: 290px;
        height: 625px;
    }
}

@media screen and (max-width: 660px){

    .aboutMeWrapper  {

        padding-top: 165px;
        padding-bottom: 165px;
    }

    .aboutMeImageWrapper {

        width: 280px;
        margin-top: -70px;
        height: 665px;
    }


}

@media screen and (max-width: 650px){

    .aboutMeImageWrapper {

        margin-top: -55px;
    }
}

@media screen and (max-width: 645px){

    .aboutMeWrapper  {

        padding-top: 185px;
        padding-bottom: 185px;
    }


    .aboutMeImageWrapper {

        width: 270px;
        margin-top: -70px;
        height: 695px;
    }

}

@media screen and (max-width: 625px){

    .aboutMeWrapper  {

        padding-top: 120px;
        padding-bottom: 100px;
    }

    .aboutMeContentContainer {

        flex: 1 0 60%;
    }

    .aboutMeHeader {

        padding-top: 40px;
    }

    .aboutMeDescription {

        padding-right: 10px;
    }

    .aboutMeImageWrapper {

        height: 170px;
        top: -0px;
        width: 30%;
        right: 220px;
    }
}

@media screen and (max-width: 600px){

    .aboutMeImageWrapper {

        right: 210px; 
        height: 167px;
        top: 4px;
    }
}

@media screen and (max-width: 575px){

    .aboutMeWrapper  {

        padding-top: 125px;
        padding-bottom: 95px;
    }

    .aboutMeImageWrapper {

        right: 200px; 
        height: 160px;
        top: 4px;
    }

    .aboutMeHeader {

        font-size: 30px;
    }
}

@media screen and (max-width: 565px){

    .aboutMeImageWrapper {

        right: 190px; 
        height: 155px;
        top: 10px;
    }

    .aboutMeHeader {

        font-size: 29px;
    }

    .dianaWords {

        padding-right: 50px;
        font-size: 14px;
    }

    .aboutMeDescription {

        padding-right: 0;
    }

}

@media screen and (max-width: 540px){

    .aboutMeImageWrapper {

        right: 180px; 
        height: 150px;
        top: 10px;
    }
}

@media screen and (max-width: 525px){

    .aboutMeWrapper  {

        padding-top: 145px;
        padding-bottom: 95px;
    }

    .dianaWords {

        padding-right: 40px;
    }
}

@media screen and (max-width: 510px){

    .aboutMeImageWrapper {

        right: 170px; 
        height: 150px;
        top: 10px;
    }
}

@media screen and (max-width: 490px){

    .aboutMeWrapper  {

        padding-top: 150px;
        padding-bottom: 125px;
    }

    .aboutMeImageWrapper {

        right: 155px; 
        height: 148px;
        top: 10px;
    }

}

@media screen and (max-width: 475px){

    .aboutMeContentWrapper {

        padding: 30px 25px;
    }
    .aboutMeHeader {

        font-size: 28px;
    }

    .dianaWords {

        line-height: 23px;
        font-size: 13px;
    }

    .aboutMeDescription {

        font-size: 15px;
    }

    .instagramShoutout {

        font-size: 15px;
    }
}

@media screen and (max-width: 450px){

    .aboutMeImageWrapper {

        right: 145px; 
        height: 145px;
        top: 5px;
    }

    .aboutMeHeader {

        padding-top: 35px;
    }
}

@media screen and (max-width: 435px){

    .aboutMeWrapper  {

        padding-top: 140px;
        padding-bottom: 125px;
    }

    .aboutMeImageWrapper {

        right: 140px; 
        height: 142px;
        top: 5px;
    }

    .aboutMeHeader {

        font-size: 27px;
        padding-top: 30px;
    }
}

@media screen and (max-width: 420px){
    
    .aboutMeWrapper  {

        padding-top: 135px;
        padding-bottom: 110px;
    }

    .aboutMeImageWrapper {

        right: 140px; 
        height: 138px;
        top: 5px;
    }

    .aboutMeHeader {

        font-size: 26px;
    }
}

@media screen and (max-width: 414px){

    .aboutMeImageWrapper {

        right: 135px; 
        height: 132px;
        top: 10px;
    }

    .aboutMeHeader {

        padding-top: 25px;
    }

}

@media screen and (max-width: 400px){

    .aboutMeImageWrapper {

        right: 130px; 
        height: 132px;
        top: 10px;
    }

}

@media screen and (max-width: 390px){

    .aboutMeWrapper  {

        padding-top: 165px;
        padding-bottom: 150px;
    }

    .aboutMeImageWrapper {

        right: 130px; 
        height: 128px;
        top: 0px;
    }

    .aboutMeHeader {

        font-size: 24px;
    }

    .dianaWords {

        font-size: 12px;
        padding-left: 20px;
        line-height: 22px;
    }

    .aboutMeDescription {

        font-size: 14px;
        padding-top: 15px;
    }

    .instagramShoutout {

        font-size: 14px;
    }
}

@media screen and (max-width: 375px){
    
    .aboutMeWrapper  {

        padding-top: 130px;
    }

    .aboutMeImageWrapper {

        right: 120px; 
        height: 124px;
        top: 5px;
    }

    .dianaWords {

        padding-right: 30px;
    }
}

@media screen and (max-width: 360px){

    .aboutMeImageWrapper {

        right: 118px; 
        height: 124px;
        top: 5px;
    }

    .aboutMeContentWrapper  {

        padding: 25px 15px;
    }

    .aboutMeDescription {

        line-height: 27px;
    }
}

@media screen and (max-width: 350px){

    .aboutMeWrapper  {

        padding-top: 120px;
    }

    .aboutMeImageWrapper {

        right: 118px; 
        height: 118px;
        top: 2px;
    }
}

@media screen and (max-width: 335px){

    .aboutMeImageWrapper {

        right: 114px; 
        height: 118px;
        top: 2px;
    }

    .aboutMeHeader {

        font-size: 23px;
    }
}

@media screen and (max-width: 325px){

    .aboutMeImageWrapper {

        right: 110px; 
        height: 115px;
        top: 2px;
    }

    .dianaWords {

        line-height: 21px;
    }

    .aboutMeDescription   {

        line-height: 26px;
    }
}

@media screen and (max-width: 320px){

    .aboutMeWrapper  {

        padding-top: 115px;
    }
}

@media screen and (max-width: 300px){

    .aboutMeImageWrapper {

        right: 105px; 
        height: 110px;
        top: 0px;
    }

    .dianaWords {

        padding-left: 15px;
        line-height: 20px;
        padding-top: 15px;
    }

}

@media screen and (max-width: 290px){

    .aboutMeImageWrapper {

        right: 98px; 
        height: 105px;
        top: 0px;
    }

    .aboutMeHeader {

        font-size: 22px;
    }
}

@media screen and (max-width: 280px){

    .aboutMeHeader {

        padding-top: 20px;
    }

    .dianaWords {

        padding-top: 10px;
    }
    
    .instagramShoutout {

        line-height: 26px;
    }
}