@font-face {
    font-family: 'Sailing South';
    src: url(../components/Fonts/Sailing\ South\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tough Regular';
    src: url(../components/Fonts/Tough\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Tropical Regular';
    src: url(../components/Fonts/Tropical\ Serif\ Font\ -\ Regular.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Cormorant SC - Italic';
    src: url(../components/Fonts/cormorant.regular-italic.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {

    font-family:"Cormorant Italic";
    src: url(../components/Fonts/cormorant.italic.otf);
    font-style: normal;
    font-weight: 400;
}

.mainWrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    justify-content: center; 

}

.mainVideo {

    z-index: -1;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.mainWordingWrapper {

    position: absolute;
}

.mainHeadingWrapper {

    display: flex;
    justify-content: center;
}

.mainHeading {

    color: white;
    font-family: 'Cormorant SC', serif;
    text-align: center;
    font-weight: 500;
}

.secondMainHeadingWrapper {

    display: flex;
    justify-content: center;
}

.secondMainHeading {

    color: white;
    text-align: center;
    font-family: 'Cormorant SC', serif;
    font-weight: 300;
}

.mainSubtitleWrapper {

    display: flex;
    justify-content: center;
}

.mainSubtitle {

    color: white;
    text-align: center;
    /* font-family: 'Cormorant Garamond', serif; */
    font-family: 'Crimson Text', serif;
    /* font-family: 'EB Garamond', serif; */
}

.secondMainSubtitleWrapper {

    display: flex;
    justify-content: center;
}

.italizedSubtitle {

    color: white;
    text-align: center;
    font-family:"Cormorant Italic";
}

.mainButtonWrapper {

    display: flex;
    justify-content: center;
    font-family: "Cormorant SC - Regular";
}


.shopButton {

    text-decoration: none;
}

.trailerButton {

    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    text-decoration: none;
}

.trailerButton:hover {

    background:  #000d1a;
}

.shopButton {

    border-radius: 2px;
    cursor: pointer;
    background-color: #ecbdc4;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    font-family: "Cormorant SC - Regular";
}

.shopButton:hover {

    background:  #000d1a;
}


@media screen and (max-width: 2400px){

    .mainWrapper {

        height: 450px;
    }

    .mainHeading {

        font-size: 68px;
    }

    .secondMainHeading {

        font-size: 30px;
    }

    .mainSubtitleWrapper {

        padding-top: 15px;
    }

    /* .secondMainSubtitleWrapper {

        padding-top: 5px;
    } */

    .mainSubtitle {

        font-size: 20px;
    }

    .italizedSubtitle {

        font-size: 26px;
    }

    .mainButtonWrapper {

        padding-top: 18px;
    }

    .watchTrailerButtonWrapper {

        margin: 16px;
    }
    

    .shopNowButtonWrapper  {

        margin: 16px;
    }



    .trailerButton {

        padding: 8px 20px;
    }

    .shopButton {

        padding: 8px 20px;
    }


}

@media screen and (max-width: 1280px){

    .mainHeading {

        font-size: 64px;
    }

    .mainSubtitle {

        font-size: 21px;
    }
}

@media screen and (max-width: 1060px){

    .mainHeading {

        font-size: 64px;
    }

    .italizedSubtitle {

        font-size: 26px;
    }

    .mainSubtitle {

        font-size: 20px;
    }
}

@media screen and (max-width: 925px){

    .mainHeading {

        font-size: 62px;
    }

    .mainSubtitle {

        font-size: 20px;
    }
}

@media screen and (max-width: 860px){

    .mainWrapper {

        height: 420px;
    }

    .mainHeadingWrapper {

        padding-left: 170px;
        padding-right: 170px;
    }

    .mainHeading {

        font-size: 62px;
    }

    .secondMainHeading {

        font-size: 26px;
    }
}

@media screen and (max-width: 820px){

    .mainHeadingWrapper {

        padding-left: 140px;
        padding-right: 140px;
    }
}

@media screen and (max-width: 775px){


    .mainHeadingWrapper {

        padding-left: 110px;
        padding-right: 110px;
    }

    .secondMainHeading {

        font-size: 24px;
    }

    .mainSubtitle {

        font-size: 19px;
    }

    .italizedSubtitle {

        font-size: 25px;
    }

}

@media screen and (max-width: 700px){

    .mainHeadingWrapper {

        padding-left: 90px;
        padding-right: 90px;
    }

}

@media screen and (max-width: 660px){

    .mainHeadingWrapper {

        padding-left: 70px;
        padding-right: 70px;
    }

    .mainSubtitleWrapper {

        padding-left: 120px;
        padding-right: 120px;
    }

}

@media screen and (max-width: 625px){

    .mainHeadingWrapper {

        padding-left: 50px;
        padding-right: 50px;
    }

    .secondMainHeading {

        font-size: 23px;
    }
}

@media screen and (max-width: 600px){

    .italizedSubtitle {

        font-size: 24px;
    }

    .trailerButton {

        font-size: 15px;
    }

    .shopButton {
        font-size: 15px;
    }
}

@media screen  and (max-width: 580px){

    .mainHeadingWrapper {

        padding-left: 30px;
        padding-right: 30px;
    }


    .mainHeading {

        font-size: 60px;
    }
}

@media screen and (max-width: 560px){

    .mainHeading {

        font-size: 60px;
    }
}

@media screen and (max-width: 540px){


    .mainHeadingWrapper  {

        padding-left: 30px;
        padding-right: 30px;
    }

    .mainHeading {

        font-size: 58px;
    }


    .mainSubtitleWrapper {

        padding-left: 80px;
        padding-right: 80px;
    }

}

@media screen and (max-width: 515px){

    .mainHeadingWrapper {

        padding-left: 20px;
        padding-right: 20px;
    }


    .mainHeading {

        font-size: 54px;
    }

    .secondMainHeading {

        font-size: 21px;
    }

    .mainSubtitleWrapper {

        padding-left: 80px;
        padding-right: 80px;
    }

}

@media screen and (max-width: 490px){

    .mainHeading {

        font-size: 54px;
    }

    .trailerButton {

        font-size: 14px;
        padding: 7px 20px;
    }

    .shopButton {

        font-size: 14px;
        padding: 7px 20px;
    }

}

@media screen and (max-width: 475px){


    .mainHeadingWrapper {

        padding-left: 10px;
        padding-right: 10px;
    }

    .mainHeading {

        font-size: 52px;
    }

    .mainSubtitleWrapper{
        
        padding-left: 70px;
        padding-right: 70px;
    }

    .mainSubtitle {

        font-size: 18px;
    }

    .italizedSubtitle {

        font-size: 23px;
    }

}

@media screen and (max-width: 460px){


    .mainHeading {

        font-size: 50px;
    }

    .secondMainHeading {

        font-size: 20px;
    }

}

@media screen and (max-width: 440px){


    .mainHeading {

        font-size: 49px;
    }

}

@media screen and (max-width: 414px){

    .mainHeading {

        font-size: 47px;
    }

    .secondMainHeading {

        font-size: 19px;
    }

    .mainSubtitleWrapper {

        padding-left: 60px;
        padding-right: 60px;
    }

    .mainSubtitle {

        font-size: 17px;
    }

    .italizedSubtitle {

        font-size: 22px;
    }

    .secondMainSubtitleWrapper  {
        
        padding-left: 70px;
        padding-right: 70px;
    }

    .trailerButton {

        font-size: 13px;
        padding: 7px 18px;
    }

    .shopButton {
        font-size: 13px;
        padding: 7px 18px;
    }


}

@media screen and (max-width: 390px){

    .mainHeading {

        font-size: 45px;
    }

    .secondMainHeading {

        font-size: 18px;
    }

    .mainSubtitleWrapper {

        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 375px){


    .mainHeadingWrapper {

        padding-left: 5px;
        padding-right: 5px;
    }

    .mainHeading {

        font-size: 43px;
    }

    .secondMainHeading {

        font-size: 18px;
    }

    .mainSubtitleWrapper {

        padding-left: 40px;
        padding-right: 40px;
    }

    .italizedSubtitle {

        font-size: 22px;
    }
}

@media screen and (max-width: 357px){


    .mainHeading {

        font-size: 41px;
    }

    .secondMainHeading {

        font-size: 17px;
    }

    .secondMainSubtitleWrapper  {
        
        padding-left: 60px;
        padding-right: 60px;
    }


    .trailerButton {

        font-size: 13px;
        padding: 7px 16px;
    }

    .shopButton {
        font-size: 13px;
        padding: 7px 16px;
    }
}

@media screen and (max-width: 335px){

    .mainHeading {

        font-size: 38px;
    }

    .mainSubtitleWrapper {

        padding-left: 30px;
        padding-right: 30px;
    }

    .secondMainSubtitleWrapper  {
        
        padding-left: 50px;
        padding-right: 50px;
    }


    .italizedSubtitle {

        font-size: 22px;
    }

    .trailerButton {

        font-size: 11px;
        padding: 7px 8px;
    }

    .shopButton {
        font-size: 11px;
        padding: 7px 8px;
    }
}
@media screen and (max-width: 320px){

    .mainHeading {

        font-size: 38px;
    }

    .secondMainHeading {

        font-size: 16px;
    }
    .mainSubtitleWrapper {

        padding-left: 10px;
        padding-right: 10px;
    }

    .secondMainSubtitleWrapper  {
        
        padding-left: 40px;
        padding-right: 40px;
    }


}

@media screen and (max-width: 305px){

    .mainHeading {

        font-size: 35px;
    }

    .secondMainHeading {

        font-size: 15px;
    }

    .mainSubtitle {

        font-size: 16px;
    }

    .italizedSubtitle {

        font-size: 21px;
    }
}

@media screen and (max-width: 290px){

    .mainHeading {

        font-size: 32px;
    }

    .italizedSubtitle {

        font-size: 21px;
    }


    .trailerButton {

        font-size: 11px;
        padding: 7px 10px;
    }

    .shopButton {
        font-size: 11px;
        padding: 7px 10px;
    }

}

@media screen and (max-width: 280px){

    .mainHeadingWrapper {

        padding-left: 5px;
        padding-right: 5px;
    }

    .mainHeading {

        font-size: 33px;
    }

    .mainSubtitle {

        font-size: 16px;
    }

    .mainSubtitleWrapper {

        padding-left: 15px;
        padding-right: 15px;
    }

    .trailerButton {

        font-size: 10px;
        padding: 7px 10px;
    }

    .shopButton {
        
        font-size: 10px;
        padding: 7px 10px;
    }
}