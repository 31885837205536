.payHereWrapper {

  background-color: #fffbff;
}

.payHereHeaderWrapper {

    display: flex;
    justify-content: center;
}

.payHereHeader {

    font-family: 'Tough Regular';
    color:  #000d1a;

}

.amountHandledWrapper {

  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.enterAmountWrapper {

  width: 50%;
  display: flex;
  justify-content: flex-end;
}


.amountInputWrapper {

  width: 50%;

}

.payInputWrapper {

  display: flex;
  justify-content: flex-start;
}

.enterAmountHeader {

    font-family: "Crimson Text", serif;
}

.orderPriceInput::-webkit-outer-spin-button,
.orderPriceInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.amountHandledButtonWrapper {

  display: flex;
  justify-content: center;
}

.amountHandledButton {

    background:  #000d1a;
    color: white;
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.amountHandledButton:hover {

  background-color: #Ffc439;

}

.payPalAppearanceWrapper {

    height:  fit-content;
    display: flex;
    justify-content: center;
}

/* .payHereButtonWrapper {
  
  border: 2px solid #000d1a;
} */



@media screen and (max-width: 2400px) {

  .fullAmountHandledWrapper  {

    padding-bottom: 30px;
  }

  .amountHandledWrapper {

    padding-top: 20px;
  }

  .payHereWrapper {

    padding-top: 45px;
  }

  .payHereHeader {

    font-size: 27px;
  }

  .payInputWrapper {

    padding-left: 3px;
  }

  .amountHandledButtonWrapper {

    padding-top: 20px;
  }

  .amountHandledButton  {

    padding: 10px 16px;

  }

  .payPalAppearanceWrapper {

    padding-top: 20px;
    padding-bottom: 20px;
  }
}


@media screen and (max-width: 460px){

  .amountHandledWrapper {
    display: block;
  }

  .enterAmountWrapper {

    width: 100%;
    justify-content: center;
  }

  .amountInputWrapper {

    width: 100%;
    justify-content: center;
  }

  .payInputWrapper {

    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-left: 0px;
  }

  .orderPriceInput {

    width: 125px;
  }

  .amountHandledButton  {

    padding: 12px 16px;

  }
}

@media screen and (max-width: 390px){

  .payHereHeader {

    font-size: 24px;
  }

  .amountHandledButton  {

    padding: 10px 12px;
    font-size: 13px;
  }
}

@media screen and (max-width: 335px){

  .payHereHeader {

      font-size: 23px;
  }

  .amountHandledButton  {

    padding: 8px 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 290px){

  .payHereHeader {

      font-size: 22px;
  }
}