.transactionDetailsWrapper {

    border: 2px solid #000d1a;
}

.transactionHeaderWrapper {

    display: flex;
    justify-content: center;
}

.paymentDetailsWrapper {

    display: flex;
    justify-content: center;
}

.whereEmailWrapper {
    
        display: flex;
        justify-content: center;
}

.emailReceiptButtonWrapper {

    display: flex;
    justify-content: center;
}

.emailReceiptButton {

    color: white;
    background-color: #Ffc439;
    border: none;
    cursor: pointer;
}

.emailReceiptButton:hover {

    background-color: pink;
}

@media screen and (max-width: 2400px){

    .transactionDetailsWrapper {

        padding: 12px 24px;
    }

    .transactionHeaderWrapper {

        padding-bottom: 10px;
    }

    .transactionHeader {

        font-size: 20px;
    }

    .transactionPurchaser {

        font-size:15px;
        padding-bottom: 6px;
    }

    .transactionEmail  {

        font-size:15px;
        padding-bottom: 6px;
    }

    .transactionOrder {

        font-size: 15px;
        padding-bottom: 6px;
    }

    .transactionStatus {

        font-size: 15px;
        padding-bottom: 6px;
    }

    .paymentDetailsHeader  {

        font-size: 20px;
        padding-bottom: 8px;
    }

    .paymentDetailTotal {
            
        font-size: 15px;

    }

    .whereEmailWrapper {

        padding-top: 20px;
    }

    .whereEmailHeader {
                
        font-size: 17px;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .paypalEmail {

        font-size: 15px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 15px;
        padding-bottom: 8px;
    }

    .anotherEmailInputWrapper {

        padding-top: 12px;
    }

    .anotherEmailInput {

        width: 180px;
        height: 20px;
    }

    .emailReceiptButtonWrapper {

        padding-top: 15px;
    }

    .emailReceiptButton {

        font-size: 14px;
        padding: 10px 12px;
    }

}

@media screen and (max-width: 460px) {

    .transactionHeaderWrapper {

        padding-bottom: 15px;
    }

    .transactionHeader {

        font-size: 19px;
    }

    .transactionPurchaser {

        font-size:14px;
        padding-bottom: 6px;
    }

    .transactionEmail  {

        font-size:14px;
        padding-bottom: 6px;
    }

    .transactionOrder {

        font-size: 14px;
        padding-bottom: 6px;
    }

    .transactionStatus {

        font-size: 14px;
        padding-bottom: 6px;
    }

    .paymentDetailsHeader  {

        font-size: 19px;
        padding-bottom: 8px;
    }

    .paymentDetailTotal {
            
        font-size: 14px;

    }

    .whereEmailHeader {
                
        font-size: 16px;
        padding-bottom: 15px;
        padding-top: 5px;
    }

    .paypalEmail {

        font-size: 14px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 14px;
        padding-bottom: 8px;
    }

    .emailReceiptButton {

        font-size: 13px;
        padding: 8px 10px;
    }

}

@media screen and (max-width: 414px){

    .transactionDetailsWrapper {

        padding: 12px 20px;
    }

    .transactionHeader {

        font-size: 18px;
    }

    .transactionPurchaser {

        font-size:13px;
        padding-bottom: 6px;
    }

    .transactionEmail  {

        font-size:13px;
        padding-bottom: 6px;
    }

    .transactionOrder {

        font-size: 13px;
        padding-bottom: 6px;
    }

    .transactionStatus {

        font-size: 13px;
        padding-bottom: 6px;
    }

    .paymentDetailsHeader  {

        font-size: 18px;
        padding-bottom: 8px;
    }

    .paymentDetailTotal {
            
        font-size: 13px;
    }

    .whereEmailHeader {
                
        font-size: 15px;
        padding-bottom: 15px;
        padding-top: 5px;
    }

    .paypalEmail {

        font-size: 13px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 13px;
        padding-bottom: 8px;
    }

    .anotherEmailInput {

        width: 170px;
        height: 19px;
    }

    .emailReceiptButton {

        font-size: 12px;
        padding: 8px 10px;
    }
}

@media screen and (max-width: 390px){

    .transactionDetailsWrapper {

        padding: 12px 16px;
    }

    .transactionHeader {

        font-size: 17px;
    }

    .transactionPurchaser {

        font-size:12px;
        padding-bottom: 6px;
    }

    .transactionEmail  {

        font-size:12px;
        padding-bottom: 6px;
    }

    .transactionOrder {

        font-size: 12px;
        padding-bottom: 6px;
    }

    .transactionStatus {

        font-size: 12px;
        padding-bottom: 6px;
    }

    .paymentDetailsHeader  {

        font-size: 17px;
        padding-bottom: 8px;
    }

    .paymentDetailTotal {
            
        font-size: 13px;
    }

    .whereEmailHeader {
                
        font-size: 14px;
        padding-bottom: 15px;
        padding-top: 0px;
    }

    .paypalEmail {

        font-size: 12px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 12px;
        padding-bottom: 8px;
    }
}

@media screen and (max-width: 360px){

    .transactionDetailsWrapper {

        padding: 10px 12px;
    }

    .transactionHeader {

        font-size: 16px;
    }

    .paymentDetailsHeader  {

        font-size: 16px;
        padding-bottom: 8px;
    }

    .whereEmailHeader {
                
        font-size: 13px;
        padding-bottom: 15px;
        padding-top: 0px;
    }

    .paypalEmail {

        font-size: 11px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 11px;
        padding-bottom: 8px;
    }

    .anotherEmailInput {

        width: 165px;
        height: 18px;
    }

}

@media screen and (max-width: 320px){

    .transactionDetailsWrapper {

        padding: 8px 8px;
    }

    .transactionHeader {

        font-size: 16px;
    }

    .transactionPurchaser {

        font-size:11px;
        padding-bottom: 6px;
    }

    .transactionEmail  {

        font-size:11px;
        padding-bottom: 6px;
    }

    .transactionOrder {

        font-size: 11px;
        padding-bottom: 6px;
    }

    .transactionStatus {

        font-size: 11px;
        padding-bottom: 6px;
    }

    .paymentDetailsHeader  {

        font-size: 16px;
        padding-bottom: 8px;
    }

    .paymentDetailTotal {
            
        font-size: 12px;
    }

    .whereEmailHeader {
                
        font-size: 13px;
        padding-bottom: 15px;
        padding-top: 0px;
    }

    .paypalEmail {

        font-size: 10px;
        padding-bottom: 8px;
    }

    .otherEmail {
            
        font-size: 12px;
        padding-bottom: 8px;
    }

    .anotherEmailInput {

        width: 160px;
        height: 17px;
    }
}

