.ultimateFlowerDisplayWrapper {

    /* background-color: #fefeff; */
        background-color: #fffbff;
}

.flowerDisplayContainer {

    /* display: inline-flex;
    justify-content: center; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


}

.flowerCard {

    border-radius: 10px;
    /* box-shadow: 0 6px 20px rgb(255, 234, 240);
    -webkit-filter: drop-shadow(0 6px 20px rgb(255, 234, 240));
    filter: drop-shadow(0 6px 20px rgb(255, 234, 240)); */
}


.flowerImage {

    object-fit: cover;
    border-radius: 8px;
}

@media screen and (max-width: 2400px){

    .flowerDisplayWrapper {

        padding-top: 50px;
        padding-bottom: 45px;
        margin: 0 200px;
    }

    .flowerCard {

        height: 230px;
        max-width: 170px;
        margin: 20px 20px;   
    }

    .flowerImage {

        height: 230px;
        max-width: 170px;
    }

}

@media screen and (max-width: 1450px){

    .flowerDisplayWrapper {

        margin: 0 170px;
    }
}

@media screen and (max-width: 1385px){

    .flowerDisplayWrapper {

        margin: 0 150px;
    }

    .flowerCard {

        height: 220px;
        max-width: 160px;
    }

    .flowerImage {

        height: 220px;
        max-width: 160px;
    }
}

@media screen and (max-width: 1300px){

    .flowerDisplayWrapper {

        margin: 0 120px;
    }

}

@media screen and (max-width: 1250px){

    .flowerCard {

        height: 210px;
        max-width: 150px;
    }

    .flowerImage {

        height: 210px;
        max-width: 150px;
    }
}

@media screen and (max-width: 1200px){

    .flowerCard {

        height: 200px;
        max-width: 140px;
    }

    .flowerImage {

        height: 200px;
        max-width: 140px;
    }
}

@media screen and (max-width: 1140px){

    .flowerDisplayWrapper {

        margin: 0 90px;
    }
}

@media screen and (max-width: 1080px){

    .flowerDisplayWrapper {

        padding-top: 60px;
        padding-bottom: 70px;
    }

    .flowerDisplayWrapper {

        margin: 0 60px;
    }
}

@media screen and (max-width: 1024px){

    .flowerCard {

        height: 190px;
        max-width: 130px;
    }

    .flowerImage {

        height: 190px;
        max-width: 130px;
    }

}

@media screen and (max-width: 975px){

    .flowerDisplayWrapper {

        margin: 0 30px;
    }
}

@media screen and (max-width: 915px){

    .flowerDisplayWrapper {

        margin: 0 15px;
    }

    .flowerCard {

        height: 180px;
        max-width: 120px;
    }

    .flowerImage {

        height: 180px;
        max-width: 120px;
    }
}


@media screen and (max-width: 715px){

    .flowerDisplayWrapper {

        padding-top: 50px;
        padding-bottom: 45px;
        
    }

    .flowerCard {

        margin: 20px 18px;
        height: 170px;
        max-width: 110px;
    }

    .flowerImage {

        height: 170px;
        max-width: 110px;
    }

}

@media screen and (max-width: 615px){

    .flowerDisplayWrapper {

        padding-top: 50px;
        padding-bottom: 45px;
    }

    .flowerCard {

        margin: 20px 16px;
        height: 165px;
        max-width: 105px;
    }

    .flowerImage {

        height: 165px;
        max-width: 105px;
    }

}

@media screen and (max-width: 575px){

    .flowerDisplayWrapper {

        padding-top: 50px;
        padding-bottom: 45px;
    }
}

@media screen and (max-width: 440px){

    .flowerDisplayWrapper {

        padding-top: 50px;
        padding-bottom: 45px;
    }

    .flowerDisplayWrapper {

        margin: 0 0;
    }

    .flowerCard {

        margin: 20px 16px;
        height: 150px;
        max-width: 95px;
    }

    .flowerImage {

        height: 150px;
        max-width: 95px;
    }
}

@media screen and (max-width: 414px){

    .flowerCard {

        margin: 18px 14px;
        height: 140px;
        max-width: 85px;
    }

    .flowerImage {

        height: 140px;
        max-width: 85px;
    }

}

@media screen and (max-width: 390px){

    .flowerCard {

        margin: 14px 10px;

    }
}

@media screen and (max-width: 375px){

    .flowerCard {

        height: 135px;
        max-width: 80px;
    }

    .flowerImage {

        height: 135px;
        max-width: 80px;
    }
}

@media screen and (max-width: 320px){

    .flowerCard {

        height: 130px;
        max-width: 75px;
    }

    .flowerImage {

        height: 130px;
        max-width: 75px;
    }
}

@media screen and (max-width: 300px){

    .flowerCard {

        height: 125px;
        max-width: 70px;
    }

    .flowerImage {

        height: 125px;
        max-width: 70px;
    }
}